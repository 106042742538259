import clsx from "clsx";
import styles from "./Button.module.css";

export const Button = ({ variant, fullwidth, className, ...props }) => {
  return (
    <button
      type="button"
      className={clsx(styles.Button, className, {
        [styles[`Button--${variant}`]]: variant,
        [styles["Button--fullwidth"]]: fullwidth,
      })}
      {...props}
    />
  );
};

export const ButtonLeading = ({ as: Component = "div", ...props }) => {
  return <Component className={styles.Button__Leading} {...props} />;
};

export const ButtonTrailing = ({ as: Component = "div", ...props }) => {
  return <Component className={styles.Button__Trailing} {...props} />;
};

import clsx from "clsx";
import styles from "./Icon.module.css";

export const Icon = ({ className, ...props }) => {
  return (
    <svg
      className={clsx(styles.Icon, className)}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
};

export const PlusCircleIcon = (props) => {
  return (
    <Icon fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export const XCircleIcon = (props) => {
  return (
    <Icon fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export const LocationsInformationIcon = ({ locationEmojiUrl, ...props }) => {
  return (
    <svg
      width="20"
      height="60"
      viewBox="0 0 120 302"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx="60" cy="131" rx="10.1282" ry="11" fill="currentColor" />
      <ellipse cx="60" cy="162" rx="10.1282" ry="11" fill="currentColor" />
      <ellipse cx="60" cy="193" rx="10.1282" ry="11" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6508 223.463C42.1087 217.404 50.8675 214 60.0004 214C69.1332 214 77.892 217.404 84.35 223.463C90.8079 229.522 94.4359 237.74 94.4359 246.309C94.4359 254.877 90.8079 263.095 84.35 269.154L60.0004 292L35.6508 269.154C32.4529 266.154 29.9162 262.593 28.1856 258.673C26.4549 254.753 25.5641 250.552 25.5641 246.309C25.5641 242.066 26.4549 237.864 28.1856 233.945C29.9162 230.025 32.4529 226.463 35.6508 223.463V223.463ZM60.0004 255.539C62.6096 255.539 65.112 254.567 66.957 252.836C68.8021 251.105 69.8386 248.757 69.8386 246.309C69.8386 243.861 68.8021 241.513 66.957 239.782C65.112 238.051 62.6096 237.078 60.0004 237.078C57.3911 237.078 54.8887 238.051 53.0437 239.782C51.1987 241.513 50.1621 243.861 50.1621 246.309C50.1621 248.757 51.1987 251.105 53.0437 252.836C54.8887 254.567 57.3911 255.539 60.0004 255.539Z"
        fill="currentColor"
      />
      <image y="0" height="110" width="110" x="5" href={locationEmojiUrl} />
    </svg>
  );
};

export const Logo = (props) => {
  return (
    <svg viewBox="0 0 400 400" fill="none" {...props}>
      <path
        d="M194.469 274.974C151.901 213.263 144 206.93 144 184.25C144 153.184 169.184 128 200.25 128C231.316 128 256.5 153.184 256.5 184.25C256.5 206.93 248.599 213.263 206.031 274.974C203.237 279.009 197.262 279.009 194.469 274.974ZM200.25 207.688C213.194 207.688 223.688 197.194 223.688 184.25C223.688 171.306 213.194 160.812 200.25 160.812C187.306 160.812 176.812 171.306 176.812 184.25C176.812 197.194 187.306 207.688 200.25 207.688Z"
        fill="#e84545"
      ></path>
      <path
        d="M318.599 167.869C319.829 172.458 319.126 178.139 315.309 185.051C311.459 192.022 304.731 199.647 295.15 207.328C276.014 222.67 247.018 237.018 212.662 246.224C178.306 255.429 146.02 257.502 121.776 253.783C109.639 251.922 100 248.683 93.1803 244.57C86.4187 240.493 82.9694 235.925 81.7397 231.335C80.51 226.746 81.2132 221.065 85.0302 214.153C88.8801 207.182 95.6081 199.557 105.189 191.876C124.325 176.534 153.321 162.186 187.677 152.98C222.033 143.775 254.319 141.702 278.563 145.421C290.7 147.283 300.339 150.522 307.159 154.634C313.92 158.712 317.37 163.28 318.599 167.869Z"
        strokeWidth="20"
        stroke="url(#paint0_linear)"
      ></path>
      <path
        d="M200.25 128C169.184 128 144 153.184 144 184.25H176.812C176.812 171.306 187.306 160.812 200.25 160.812C213.194 160.812 223.688 171.306 223.688 184.25H256.5C256.5 153.184 231.316 128 200.25 128Z"
        fill="#e84545"
      ></path>
      <circle cy="262" r="16" cx="299" fill="#e84545"></circle>
      <circle cx="99" cy="144" r="16" fill="#e84545"></circle>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="202.5"
          y1="144"
          x2="248.5"
          y2="237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A2734"></stop>
          <stop offset="1" stopColor="#4F4F4F"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const TwitterIcon = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="#9fa5b1"
      strokeWidth="0"
      viewBox="0 0 1024 1024"
      height="2em"
      width="2em"
      {...props}
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 0 1-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 0 1-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 0 0 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z"></path>
    </svg>
  );
};

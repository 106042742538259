import clsx from "clsx";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import buttonStyles from "./Button.module.css";
import { Logo, TwitterIcon } from "./Icon";
import styles from "./Layout.module.css";
import MailChimpForm from "./MailChimpForm";

export const Header = () => {
  return (
    <header className={styles.Header}>
      <Link to="/" aria-label="Accueil">
        <Logo className={styles.Logo} />
      </Link>
      <nav>
        <Link className={styles.Header__link} to="/a-propos">
          À propos
        </Link>
      </nav>
    </header>
  );
};

export const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <div className={styles.FirstFooter}>
        <div className={styles.NewsletterMsg}>
          <span>V2 BIENTOT DISPONIBLE</span>
          <span>
            Vous serez directement informé de la sortie des dernières
            nouveautés.
          </span>
        </div>

        <div className={styles.containerInputBtn}>
          <MailChimpForm />
        </div>
      </div>

      <div className={styles.FirstFooter}>
        <div className={styles.NewsletterMsg}>
          <span>
            <Link
              to={{ pathname: "https://becoms.tech/" }}
              rel="noreferrer"
              target="_blank"
              className={styles.NewsletterMsgLink}
            >
              &copy; 2021 Becoms.
            </Link>{" "}
            | Tous droits réservés.
            <Link to="/mentions-legales" className={styles.NewsletterMsgLink}>
              {" "}
              | Mentions légales.
            </Link>
            <Link
              to="/politique-de-confidentialite"
              className={styles.NewsletterMsgLink}
            >
              {" "}
              | Politique de confidentialité.
            </Link>
          </span>
        </div>
        <div className={styles.SocicalMediaLogos}>
          <TwitterIcon className={styles.SocialMedia} />
        </div>
      </div>
    </footer>
  );
};

export const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <CookieConsent
        disableStyles={true}
        enableDeclineButton={true}
        overlay={true}
        buttonText="J'accepte"
        ariaAcceptLabel="J'accepte"
        declineButtonText="Je refuse"
        ariaDeclineLabel="Refuser"
        containerClasses={styles.CookieConsentContainer}
        contentClasses={styles.CookieConsentContent}
        overlayClasses={styles.CookieConsentOverlay}
        buttonWrapperClasses={styles.CookieConsentButtonWrapper}
        buttonClasses={clsx(
          buttonStyles.Button,
          buttonStyles["Button--primary"],
          buttonStyles.Button__Trailing
        )}
        declineButtonClasses={buttonStyles.Button}
        onDecline={() => {
          if (window["ga-disable-GA_MEASUREMENT_ID"]) {
            window["ga-disable-GA_MEASUREMENT_ID"] = true;
          }
        }}
      >
        En plein milieu utilise des cookies pour offrir une expérience
        utilisateur de qualité. En poursuivant votre navigation sur ce site,
        vous acceptez l’utilisation de cookies dans les conditions prévues par{" "}
        <Link to="/politique-de-confidentialite">
          notre politique de confidentialité.
        </Link>
      </CookieConsent>
    </>
  );
};

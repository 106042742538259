import { Button } from "./Button";
import styles from "./ErrorState.module.css";

const ErrorState = ({ error, resetErrorBoundary }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
          className={styles.emoji}
        >
          <path
            fill="#FFCC4D"
            d="M36 18c0 9.941-8.059 18-18 18-9.94 0-18-8.059-18-18C0 8.06 8.06 0 18 0c9.941 0 18 8.06 18 18"
          />
          <ellipse fill="#664500" cx="11.5" cy="16.5" rx="2.5" ry="3.5" />
          <ellipse fill="#664500" cx="24.5" cy="16.5" rx="2.5" ry="3.5" />
          <path fill="#664500" d="M12 28c2-5 13-5 13-3 0 1-8-1-13 3z" />
        </svg>
        <h1 className={styles.title}>Oops.. </h1>
        <p>
          On dirait que quelque chose c'est mal passé. Cliquez sur le bouton
          ci-dessous pour essayer de recharger la page.
        </p>
        {error && (
          <details className={styles.errorDetails}>
            <summary className={styles.errorSummary}>
              Afficher le détail de l'erreur
            </summary>
            <p className={styles.errorDetailsMessage}>{error}</p>
          </details>
        )}
        <Button variant="primary" onClick={resetErrorBoundary}>
          Réessayer
        </Button>
      </div>
    </div>
  );
};

export default ErrorState;

import { Button, ButtonTrailing } from "./Button";
import styles from "./MailChimpForm.module.css";

const MailChimpForm = () => {
  return (
    <form
      action="https://tech.us9.list-manage.com/subscribe/post?u=0d79c1109910a2e0502e9f3c7&amp;id=52f471bb9a"
      method="post"
      target="_blank"
      className={styles.form}
      noValidate
    >
      <input
        className={styles.input}
        type="email"
        name="EMAIL"
        placeholder="Adresse email"
      />
      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div id="mce-responses" className="clear">
        <div
          className="response"
          id="mce-error-response"
          style={{ display: "none" }}
        ></div>
        <div
          className="response"
          id="mce-success-response"
          style={{ display: "none" }}
        ></div>
      </div>
      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input
          type="text"
          name="b_0d79c1109910a2e0502e9f3c7_52f471bb9a"
          tabIndex="-1"
        />
      </div>
      <div className="clear">
        <ButtonTrailing>
          <Button type="submit" name="subscribe">
            Envoyer
          </Button>
        </ButtonTrailing>
      </div>
    </form>
  );
};

export default MailChimpForm;
